import React from 'react';
import { graphql } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

// Components
import SeoDetails from '../components/common/seoDetails';
import ModalClose from '../components/common/modalClose';
import HeroImage from '../components/common/sections/heroImage';
import Features from '../components/common/sections/features';
import CarouselSimilar from '../components/common/sections/carouselSimilar';
import RoomImages from '../components/common/sections/roomImages';
import Freezer from '../components/common/freezer';

const RoomPage = ({ data }) => {
  const {
    id,
    seoContent,
    contentSections,
    features,
    roomTypeId
  } = data.contentfulRooms;
  const roomData = data.allContentfulRooms.edges;
  const filterRooms = roomData.filter(({ node }) => node.id !== id);

  return (
    <>
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <div>
            {modal ? (
              <>
                <SeoDetails modal seoContent={seoContent} />
                <ModalClose closeTo={closeTo} />
                <HeroImage modal isRoom seoContent={seoContent} />
                <Features
                  modal
                  contentSection={contentSections[0]}
                  features={features}
                  roomTypeId={roomTypeId}
                />
                <Freezer modal />
              </>
            ) : (
              <>
                <SeoDetails seoContent={seoContent} />
                <HeroImage isRoom seoContent={seoContent} />
                <Features
                  contentSection={contentSections[0]}
                  features={features}
                  roomTypeId={roomTypeId}
                />
                <CarouselSimilar itemData={filterRooms} />
                <RoomImages
                  images={contentSections[1]}
                  title={seoContent.menuTitle}
                />
              </>
            )}
          </div>
        )}
      </ModalRoutingContext.Consumer>
    </>
  );
};

export const query = graphql`
  query roomQuery($slug: String!) {
    contentfulRooms(seoContent: { slug: { eq: $slug } }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        imageGallery {
          id
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      roomTypeId
      features {
        id
        icon {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        title
      }
    }
    allContentfulRooms {
      edges {
        node {
          id
          seoContent {
            featuredImage {
              title
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            menuTitle
            pageTitle
            slug
          }
          features {
            id
            title
          }
        }
      }
    }
  }
`;

export default RoomPage;
